<template>
    <li class="list__item item item_view_head"
        :class="{'item_type_collapses-group': true, 'item_status_collapsed': false, 'item_active': showActive == true}"
        @click.stop="showActive = !showActive"
    >
        <h4 class="list__title"> {{title}} </h4>
    </li>
</template>
<script>
    import {
        mapActions,
        // mapMutations,
        // mapGetters,
    } from "vuex";

    export default {
        name: 'UnitsList_group',
        components: {},
        props: [
            'title',
        ],
        data() {
            return {
                showActive: false,
            }
        },
        computed: {
            // ...mapGetters([
            // ]),
        },
        methods: {
            ...mapActions([
                "setGroupShowUnits",
            ]),
        },
        updated() {
            //console.log(`group ${this.groupId} updated`);
        }
    }
</script>