<template>
    <!--- Navigation --->
    <nav class="section__navigation navigation pfull__12">
        <div class="horizontal-line">
            <div class="filter-search filter-search_has-filter mr__8 "
                 :class="{'filter-search_active': FilterSearchValue>'','filter-search_focus': FilterSearchFocusin}"
            >
                <button class="filter-search__filter button button_view_icon button_theme_primary button_dropdown-menu"
                        @click="switchShowFilterDropdownMenu"
                >
                    <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__slider" /></span> </span>
                </button>
                <ul class="filter-search__choice">
                    <li class="filter-search__choice-item"
                        v-show="unitsFilter.signal"
                        @click="setUnitsFilterParam('signal',false)"
                    >
                        {{FilterSearchSignalText}}
                    </li>
                    <li class="filter-search__choice-item"
                        v-show="unitsFilter.movement"
                        @click="setUnitsFilterParam('movement',false)"
                    >
                        {{FilterSearchMovementText}}
                    </li>
                    <li class="filter-search__choice-item"
                        v-show="unitsFilter.type"
                        @click="setUnitsFilterParam('type',false)"
                    >
                        {{FilterSearchTypeText}}
                    </li>
                </ul>
                <input class="filter-search__input" type="text" :placeholder="$t('text.Search')" v-model="FilterSearchValue" @focus="FilterSearchFocusin=true" @blur="FilterSearchFocusin=false">
                <button class="filter-search__close button button_view_icon button_theme_primary" @click="FilterSearchValue=''">
                    <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__close_sm" /></span> </span>
                </button>
                <div class="filter-search__dropdown-menu dropdown-menu"
                     :class="{'dropdown-menu_open': showFilterDropdownMenu}"
                >
                    <div class="dropdown-menu__wrap">
                        <ul class="dropdown-menu__list list">
                            <li class="dropdown-menu__item item item_has-checkbox" data-type="all"
                                :class="{'item_active': isAll}"
                                @click="resetUnitsFilter"
                            >
                                <span class="dropdown-menu__icon icon icon_type_checkbox"> <span class="icon__symbol"><SvgIcon class="icon" name="common__checkbox" /></span> </span>
                                <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__car" /></span> </span>
                                <span class="dropdown-menu__item-name">{{ $t('Units.status.All units') }}</span>
                            </li>

                            <li class="dropdown-menu__item item item_type_separator"></li>
                            <li class="dropdown-menu__item item item_has-checkbox" data-type="signal"
                                :class="{'item_active': unitsFilter.signal==='on'}"
                                @click="setUnitsFilterParam('signal','on')"
                            >
                                <span class="dropdown-menu__icon icon icon_type_checkbox"> <span class="icon__symbol"><SvgIcon class="icon" name="common__checkbox" /></span> </span>
                                <span class="dropdown-menu__icon icon is_success"> <span class="icon__symbol"><SvgIcon class="icon" name="common__signal" /></span> </span>
                                <span class="dropdown-menu__item-name">{{ $t('Units.signals.ON line') }}</span>
                            </li>
                            <li class="dropdown-menu__item item item_has-checkbox" data-type="signal"
                                :class="{'item_active': unitsFilter.signal==='off'}"
                                @click="setUnitsFilterParam('signal','off')"
                            >
                                <span class="dropdown-menu__icon icon icon_type_checkbox"> <span class="icon__symbol"><SvgIcon class="icon" name="common__checkbox" /></span> </span>
                                <span class="dropdown-menu__icon icon is_danger"> <span class="icon__symbol"><SvgIcon class="icon" name="common__signal_lost" /></span> </span>
                                <span class="dropdown-menu__item-name">{{ $t('Units.signals.OFF line') }}</span>
                            </li>

                            <li class="dropdown-menu__item item item_type_separator"></li>
                            <li class="dropdown-menu__item item item_has-checkbox" data-type="movement"
                                :class="{'item_active': unitsFilter.movement==='moving'}"
                                @click="setUnitsFilterParam('movement','moving')"
                            >
                                <span class="dropdown-menu__icon icon icon_type_checkbox"> <span class="icon__symbol"><SvgIcon class="icon" name="common__checkbox" /></span> </span>
                                <span class="dropdown-menu__icon icon is_success"> <span class="icon__symbol"><SvgIcon class="icon" name="common__movement" /></span> </span>
                                <span class="dropdown-menu__item-name">{{ $t('Units.movements.Moving') }}</span>
                            </li>
                            <li class="dropdown-menu__item item item_has-checkbox" data-type="movement"
                                :class="{'item_active': unitsFilter.movement==='stopped'}"
                                @click="setUnitsFilterParam('movement','stopped')"
                            >
                                <span class="dropdown-menu__icon icon icon_type_checkbox"> <span class="icon__symbol"><SvgIcon class="icon" name="common__checkbox" /></span> </span>
                                <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__parking" /></span> </span>
                                <span class="dropdown-menu__item-name">{{ $t('Units.movements.Stopped') }}</span>
                            </li>
                            <li class="dropdown-menu__item item item_has-checkbox" data-type="movement"
                                :class="{'item_active': unitsFilter.movement==='ignition'}"
                                @click="setUnitsFilterParam('movement','ignition')"
                            >
                                <span class="dropdown-menu__icon icon icon_type_checkbox"> <span class="icon__symbol"><SvgIcon class="icon" name="common__checkbox" /></span> </span>
                                <span class="dropdown-menu__icon icon is_warning"> <span class="icon__symbol"><SvgIcon class="icon" name="common__ignition" /></span> </span>
                                <span class="dropdown-menu__item-name">{{ $t('Units.movements.Ignition on') }}</span>
                            </li>

                            <template v-if="!isLite">
                            <li class="dropdown-menu__item item item_type_separator"></li>
                            <li class="dropdown-menu__item item item_has-checkbox" data-type="type"
                                v-for="type in getFilterSearchLists.Units.types"
                                :key="type.id"
                                :class="{'item_active': unitsFilter.type===type.id}"
                                @click="setUnitsFilterParam('type', type.id)"
                            >
                                <span class="dropdown-menu__icon icon icon_type_checkbox"> <span class="icon__symbol"><SvgIcon class="icon" name="common__checkbox" /></span> </span>
                                <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" :name="'common__'+type.icon" /></span> </span>
                                <span class="dropdown-menu__item-name">{{$t(type.translate)}} </span>
                            </li>
                            </template>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="navigation__button-group">
                <button class="button button_view_icon button_theme_base mtb__-4" data-type="open-drop-menu">
                    <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__more_vertical" /></span> </span>
                </button>
                <div class="navigation__dropdown-menu dropdown-menu position_right-bottom" data-type="" >
                    <div class="dropdown-menu__wrap">
                        <ul class="dropdown-menu__list">
                            <li class="dropdown-menu__item item" v-show="!isAllUnitsShowOnMap" @click="_setUnitsShowOnMap({showOnMap: true, unitIds: filteredUnits})">
                                <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__show" /></span> </span>
                                <span class="dropdown-menu__item-name">{{ $t('menu.Show all objects on map') }}</span>
                            </li>
                            <li class="dropdown-menu__item item" v-show="isAllUnitsShowOnMap" @click="_setUnitsShowOnMap({showOnMap: false, unitIds: filteredUnits})">
                                <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__show_disabled" /></span> </span>
                                <span class="dropdown-menu__item-name">{{ $t('menu.Hide all objects on map') }}</span>
                            </li>

                            <template v-if="false">
                            <li class="dropdown-menu__item item item_type_separator"></li>
                            <li class="dropdown-menu__item item" @click="displaySectionInfo('UnitEdit')">
                                <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__add" /></span> </span>
                                <span class="dropdown-menu__item-name">{{ $t('menu.Add a unit') }}</span>
                            </li>
                            </template>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
    import { mapActions, mapGetters, mapMutations} from "vuex";

    export default {
        name: "UnitsList_navigation",
        props: [],
        components: {},
        data() {
            return {
                FilterSearchFocusin: false,
            }
        },
        computed: {
            ...mapGetters([
                "isLite",
                "unitsCount",
                "unitsOnMap",
                "unitsFilter",
                "filteredUnits",
                "getFilterSearchLists",
                "showFilterDropdownMenu",
                "getAppUser"
            ]),
            isAll(){
                return !this.unitsFilter.signal
                    && !this.unitsFilter.movement
                    && !this.unitsFilter.type
            },
            isAllUnitsShowOnMap() {
                // console.log('isAllUnitsShowOnMap', this.unitsCount, this.unitsOnMap.length)
                //return this.unitsOnMap.length == this.unitsCount
                let ids = this.filteredUnits.filter(id => {
                    return !this.unitsOnMap.includes(id)
                })
                return ids.length === 0
            },
            FilterSearchSignalText() {
                let signal = this.getFilterSearchLists.Units.signals.find(s => {
                    return s.id === this.unitsFilter.signal
                })
                return (signal)? this.$t(signal.translate) : ''
            },
            FilterSearchMovementText() {
                let movement = this.getFilterSearchLists.Units.movements.find(s => {
                    return s.id === this.unitsFilter.movement
                })
                return (movement)? this.$t(movement.translate) : ''
            },
            FilterSearchTypeText() {
                let type = this.getFilterSearchLists.Units.types.find(s => {
                    return s.id === this.unitsFilter.type
                })
                return (type)? this.$t(type.translate) : ''
            },
            FilterSearchValue: {
                get(){
                    // console.log('FilterSearchValue.get', this.unitsFilter.name)
                    return this.unitsFilter.name
                },
                set(value){
                    let unitsFilter = this.unitsFilter
                    unitsFilter.name = value
                    this.setUnitsFilter(unitsFilter)
                },
            },
        },
        methods: {
            ...mapActions([
                "setMapBoundsByUnits",
                "setUnitsShowOnMap",
                "setSectionInfo",
                "setSectionWidget"
            ]),
            ...mapMutations([
                "setUnitsFilter",
                "switchShowFilterDropdownMenu",
                "setShowFilterDropdownMenu",
            ]),
            displaySectionInfo(component){
                this.setSectionInfo({
                    component: component,
                    props: {
                        unitId: null,
                        time: Date.now()
                    }
                })
            },
            displaySectionWidget(component){
                this.setSectionWidget({
                    component: component,
                    props: {
                        unitId: null,
                        time: Date.now()
                    }
                })
            },
            setUnitsFilterParam(param, value){
                let unitsFilter = this.unitsFilter
                unitsFilter[param] = value
                this.setUnitsFilter(unitsFilter)
            },
            resetUnitsFilter(){
                let unitsFilter = this.unitsFilter
                unitsFilter.signal = false
                unitsFilter.movement = false
                unitsFilter.type = false
                // let unitsFilter = {
                //     signal: false,
                //     movement: false,
                //     type: false,
                //     name: '',
                // }
                this.setUnitsFilter(unitsFilter)
            },
            _setUnitsShowOnMap(args){//{showOnMap: true, unitIds: filteredUnits}
                this.setUnitsShowOnMap(args)
                if(args.showOnMap) {
                    this.setMapBoundsByUnits(args.unitIds)
                }
            }
        },
        updated() {
            //console.log(`UnitsList_navigation updated`)
        },
        mounted() {
        },
    }
</script>

<style scoped>

</style>