<template>
    <section class="section__body">

        <!--- Navigation --->
        <UnitsList_navigation />

        <!--- Container --->
        <section class="section__container container container_active separator-when-scrolling plr__12 pb__12">
<!--            <ul class="list mb__12">-->
            <DynamicScroller
                    :items="unitsGroupsWithSize"
                    :min-item-size="56"
                    :buffer="100"
                    page-mode
            >
                <template #before>
                    <!-- start -->
                </template>

                <template v-slot="{ item, active }">
                    <DynamicScrollerItem
                            :item="item"
                            :active="active"
                            :size-dependencies="[filteredUnits,item.units,]"
                            tag="ul"
                            class="list mb__12"
                    >
                    <UnitsList_group_lite
                            :key="'UnitsList-group-'+item.id"
                            :title="item.name"
                    />

                    <UnitsList_unit
                            v-for="unitId in item.units"
                            :key="'UnitsList-group-'+item.id+'-unit-'+unitId"
                            :unit-id="unitId"
                            :group-id="item.id"
                            :show-unit="true"
                    />
                    </DynamicScrollerItem>
                </template>

                <template #after>
                    <!--  end -->
                </template>
            </DynamicScroller>
<!--            </ul>-->
        </section>
    </section>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import UnitsList_navigation from "./UnitsList_navigation.lite.vue";
    import UnitsList_group_lite from "./UnitsList_group.lite.vue";
    import UnitsList_unit from "./UnitsList_unit.vue"

    export default {
        name: "UnitsList",
        components: {
            // VueAutoVirtualScrollList,
            UnitsList_navigation,
            UnitsList_group_lite,
            UnitsList_unit,
        },
        data() {
            return {
                pageActive: false,
            }
        },
        computed: {
            ...mapGetters([
                "units",
                "unitsCount",
                "unitsByIds",
                "filteredUnits",
            ]),
            unitsGroupsWithSize(){
                // console.log('unitsGroupsWithSize', this.unitsGroups, this.filteredUnits)
                let size = 52*this.filteredUnits.length
                return [{
                    id: -1,
                    name: this.$t('text.Units')+' ('+this.unitsCount+')',
                    units: this.units.map(u => u.id),
                    size: 44+12+size
                }]
            }
        },
        methods: {
            ...mapActions([
                "fetchUnits",
            ]),
        },
        created() {
        },
        updated() {
            // console.log('UnitsList updated'
            //     // , this.unitsGroupsFilteredWithSize.filter(g => { return g.size>44+12 }).map(g => g.id)
            //     // , this.unitsGroupsWithSize.filter(g => { return g.size>44+12 }).map(g => g.id)
            // )
        },
        activated() {
            this.pageActive = true
        },
        deactivated() {
            this.pageActive = false
        },
        mounted(){
        },
        beforeDestroy() {
        },
    }
</script>

<style scoped>

</style>